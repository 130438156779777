/* eslint-disable i18next/no-literal-string */
import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from '@remix-run/node';

import { getFormProps, getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { json } from '@remix-run/node';
import { Form, useActionData, useSearchParams } from '@remix-run/react';
import { Loader2Icon } from 'lucide-react';
import { HoneypotInputs } from 'remix-utils/honeypot/react';
import { z } from 'zod';

import { Button } from '~/components/ui/button';
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from '~/components/ui/card';
import { CheckboxField } from '~/components/ui/checkbox';
import { Field } from '~/components/ui/input';
import { checkHoneypot } from '~/lib/honeypot.server';
import {
  AUTH_STRATEGY_NAME,
  authenticator,
  handleNewSession,
  requireAnonymous,
} from '~/services/auth.server';
import { useIsPending } from '~/utils/misc';
import { LoginSchema } from '~/validation';

const schema = LoginSchema.extend({
  redirectTo: z.string().optional(),
  remember: z
    .string()
    .optional()
    .transform((value) => value === 'on'),
});

export async function action({ request }: ActionFunctionArgs) {
  await requireAnonymous(request);
  const formData = await request.clone().formData();

  checkHoneypot(formData);

  const submission = await parseWithZod(formData, {
    async: true,
    schema: (intent) =>
      schema.transform(async (data, ctx) => {
        if (intent !== null) return { ...data, userSession: null };

        const userSession = await authenticator.authenticate(AUTH_STRATEGY_NAME, request);

        if (!userSession) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid username or password',
          });

          return z.NEVER;
        }

        return { ...data, userSession };
      }),
  });

  if (submission.status !== 'success' || !submission.value?.userSession) {
    return json(
      { result: submission.reply({ hideFields: ['password'] }) },
      { status: submission.status === 'error' ? 400 : 200 }
    );
  }

  const { userSession, remember, redirectTo } = submission.value;

  return handleNewSession({
    request,
    userSession,
    redirectTo,
    remember: remember ?? false,
  });
}

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAnonymous(request);

  return json({});
}

export const meta: MetaFunction = () => {
  return [{ title: 'Login to STARRT' }];
};

export default function Login() {
  const actionData = useActionData<typeof action>();
  const isPending = useIsPending();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo') ?? '';

  const [form, fields] = useForm({
    id: 'login-form',
    constraint: getZodConstraint(schema),
    defaultValue: { redirectTo },
    lastResult: actionData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: schema });
    },
    shouldRevalidate: 'onBlur',
  });

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold">Login</CardTitle>
        <CardDescription>Enter your email and password to login to your account</CardDescription>
      </CardHeader>
      <CardContent>
        <Form method="POST" className="space-y-4" {...getFormProps(form)}>
          <HoneypotInputs />

          <Field
            labelProps={{ children: 'Username' }}
            inputProps={{
              ...getInputProps(fields.username, { type: 'text' }),
              autoFocus: true,
              className: 'lowercase',
              autoComplete: 'username',
            }}
            errors={fields.username.errors}
          />

          <Field
            labelProps={{ children: 'Password' }}
            inputProps={{
              ...getInputProps(fields.password, {
                type: 'password',
              }),
              autoComplete: 'current-password',
            }}
            errors={fields.password.errors}
          />

          <CheckboxField
            labelProps={{
              htmlFor: fields.remember.id,
              children: 'Remember me',
            }}
            buttonProps={getInputProps(fields.remember, {
              type: 'checkbox',
            })}
            errors={fields.remember.errors}
          />

          <input {...getInputProps(fields.redirectTo, { type: 'hidden' })} />

          <Button size="lg" className="w-full" disabled={isPending}>
            {isPending ? <Loader2Icon className="mr-2 h-5 w-5 animate-spin" /> : <>Login</>}
          </Button>
        </Form>
      </CardContent>
    </Card>
  );
}
